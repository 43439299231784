import { render, staticRenderFns } from "./documnet-upload.vue?vue&type=template&id=4cdd5048&"
import script from "./documnet-upload.vue?vue&type=script&lang=js&"
export * from "./documnet-upload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports